<template>
  <span
    :tabindex="!!action ? 1 : -1"
    class="icon-indicator"
    @click="(e) => action?.(e)"
    @keydown.enter="(e) => action?.(e)"
    v-class-mod:icon-indicator="{ hasAction: !!action, hasIcon: !!icon }"
  >
    <span v-if="backdrop" class="icon-indicator__backdrop" v-set-colors="{ background: backdrop }" />
    <div class="icon-indicator__content">
      <span v-if="icon" :class="`h56-icons-before--${icon}`" class="icon-indicator__icon" />

      <span
        v-if="indicator?.amount"
        class="icon-indicator__amount"
        v-set-colors="{ color: indicator?.color, background: indicator?.background ?? 'black-20' }"
      >
        {{ indicator.amount }}
      </span>

      <icon-wrapper
        v-if="errors?.length"
        :tooltip="{ explanation: errors.join(', ') }"
        icon="warning"
        size="medium"
        color="yellow-vivid"
        class="base-label__warning"
      />
    </div>
    <tooltip v-if="tooltip" v-bind="tooltip">
      <span class="icon-indicator__tooltip" />
    </tooltip>
  </span>
</template>

<script setup lang="ts">
import { defineAsyncComponent } from "vue";

import { vSetColors } from "@horizon56/directives/set-colors";
import { IconName } from "@horizon56/fonts/types";
import { ColorName } from "@horizon56/styles/types";

import Tooltip from "@/components/tooltips/tool-tip.vue";

const IconWrapper = defineAsyncComponent(() => import("@/components/icons/icon-wrapper.vue"));

defineProps<{
  icon?: IconName;
  tooltip?: Pick<InstanceType<typeof Tooltip>, "title" | "explanation" | "shortcut">;
  backdrop?: ColorName;
  indicator?: {
    amount: number;
    background?: ColorName;
    color?: ColorName;
  };
  action?: (event: MouseEvent | KeyboardEvent) => void;
  errors?: string[];
}>();
</script>

<style lang="scss" scoped>
.icon-indicator {
  $block: &;
  display: inline-flex;
  flex-flow: row nowrap;
  flex-shrink: 0;
  position: relative;
  --amount-size: var(--app-icon-size-medium);
  &__content {
    display: flex;
    gap: var(--app-spacing-size-xsmall);
    flex-flow: row nowrap;
  }
  &__backdrop {
    position: absolute;
    @include position-corner;
    z-index: -1;
    border-radius: var(--app-radius-large);
  }
  &__icon {
    color: var(--black-90);
    height: var(--app-icon-size-medium);
    font-size: var(--app-icon-size-medium);
  }
  &__amount {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--amount-size);
    min-width: var(--amount-size);
    height: var(--amount-size);
    line-height: var(--amount-size);
    padding: 0 4px;
    font-size: var(--app-font-size-label);
    font-weight: 500;
    text-align: center;
  }
  &__tooltip {
    position: absolute;
    @include position-corner();
  }
  &--hasIcon &__content {
    background: var(--black-20);
    border-radius: var(--app-radius-large);
    padding: 4px;
  }
  &--hasAction {
    cursor: pointer;
    #{$block}__content {
      background: var(--black-10);
    }
    color: var(--black-50);
    #{$block}__icon {
      color: var(--black-50);
    }
    &:hover {
      color: var(--black-90);
      #{$block}__content {
        background: var(--black-20);
      }
      #{$block}__icon {
        color: var(--black-90);
      }
    }
  }
  &:not(#{&}--hasIcon) &__amount {
    --amount-size: var(--app-icon-size-large);
  }
  &:has(#{&}__backdrop) {
    #{$block}__content {
      background: transparent;
    }
    &:hover #{$block}__content {
      background: var(--black-10);
    }
  }
}
</style>
